import React, { useRef, useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import {
  Layout,
  SEO,
  CJumbotron,
  CBreadCrumb,
  LWrap,
  CSectTitle03,
  CAnchorNav,
  CCard02,
  CTable02,
  CTable04,
  LContact03,
  CImgCard,
  CImgCard2,
} from '../../../components/_index';
import stayPlanGet from '../../../utils/stayPlan-get';

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;
  const stayPlanData = stayPlanGet().filter((item: any) => {
    return item.node.category.includes('記念日・プロポーズ');
  });

  return (
    <Layout>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CJumbotron
        size="large"
        type="ja"
        data={{
          title: {
            main: 'バルーンデコレーション',
            sub: <>BALLOON DECORATION</>,
          },
          imgList: [
            {
              img: {
                src: '/assets/images/anniversary/balloon_anniversary/kv.png',
              },
              imgSp: {
                src: '/assets/images/anniversary/balloon_anniversary/kv__sp.png',
              },
            },
          ],
        }}
      ></CJumbotron>
      <div className="l_sect02 u_pb0">
        <LWrap>
          <p className="c_headingLv2 u_mb10">
            人生の記念日をお祝いするバルーンデコレーション
          </p>
          <p className="c_sectLead u_mb40">
            お誕生日や記念日のお祝い、プロポーズなどご要望にそったデザインをお選びいただけます。<br />
            大切なご友人やパートナー、ご家族へ想いを込めたバルーンとともに忘れられないひとときを。
          </p>
          <CAnchorNav
            exClass="u_mb0 u_mb100"
            data={[
              {
                title: <>プロポーズプラン</>,
                link: {
                  href: '/anniversary/balloon_anniversary/#propose',
                },
              },
              {
                title: <>バースデープラン</>,
                link: {
                  href: '/anniversary/balloon_anniversary/#birthday',
                },
              },
              {
                title: <>卓上タイププラン</>,
                link: {
                  href: '/anniversary/balloon_anniversary/#desktopPlan',
                },
              },
              {
                title: <>ご利用方法</>,
                link: {
                  href: '/anniversary/balloon_anniversary/#usage',
                },
              },
            ]}
          />

        </LWrap>
      </div>
      <section>
        <LWrap>
          <div id="propose" className="u_pt100">
            <CSectTitle03 title="プロポーズプラン" />
            <CCard02
              col={3}
              exClass="u_mb0"
              data={[
                {
                  img: {
                    src: '/assets/images/anniversary/balloon_anniversary/img_balloon_anniversary.png',
                    alt: '',
                  },
                  title: <>プロポーズプラン A</>,

                  text: (
                    <>
                      <p>
                        ロイヤルパークホテルのロゴであるバラのつぼみを大きく開花させたバルーンがお部屋を豪華に彩ります。バラのようにおふたりの未来に大輪の花が開くことを願って装飾させていただきます。
                      </p>
                      <p className="u_fwb u_mt10">料金:20,000円</p>
                    </>
                  ),
                },
                {
                  img: {
                    src: '/assets/images/anniversary/balloon_anniversary/img_balloon_anniversary02.png',
                    alt: '',
                  },
                  title: <>プロポーズプラン B</>,

                  text: (
                    <>
                      <p>
                        淡いピンクのバルーンとバラの花びらがベッドの上に降り注がれ、大きな「Love」をやさしく包み込みます。言葉では言い尽くせない愛をたくさんのバルーンに込めて、素敵な空間を演出いたします。
                      </p>
                      <p className="u_fwb u_mt10">料金:28,000円</p>
                    </>
                  ),
                },
                {
                  img: {
                    src: '/assets/images/anniversary/balloon_anniversary/img_balloon_anniversary03.png',
                    alt: '',
                  },
                  title: <>プロポーズプラン C</>,

                  text: (
                    <>
                      <p>
                        たくさんのバルーンが「MARRY
                        ME」の文字の背中を押すように華やかに演出いたします。愛する人へ伝えるその時【愛情、感謝、決意】たくさんの想いが溢れます。大切な日だからこそ、しっかりと想いが伝わりますよう心を込めてお作りいたします。
                      </p>
                      <p className="u_fwb u_mt10">料金:45,000円</p>
                    </>
                  ),
                },
              ]}
            />
          </div>
          <div id="birthday" className="u_pt100">
            <CSectTitle03 title="バースデープラン" />
            <CCard02
              col={3}
              exClass="u_mb0"
              data={[
                {
                  img: {
                    src: '/assets/images/anniversary/balloon_anniversary/img_balloon_anniversary04.png',
                    alt: '',
                  },
                  title: <>バースデープラン スタンダード</>,

                  text: (
                    <>
                      <p>
                        タオルアートの白鳥は支え合うおふたりをイメージし、お花が咲いているように束ねられたバルーンはお誕生日の方を心から祝福していることを表現しています。大好きな人に送りたいと思っていただけるような空間をお作りいたします。
                      </p>
                      <p className="u_fwb u_mt10">料金:23,000円</p>
                    </>
                  ),
                },
                {
                  img: {
                    src: '/assets/images/anniversary/balloon_anniversary/img_balloon_anniversary05.png',
                    alt: '',
                  },
                  title: <>お子様バースデープラン</>,

                  text: (
                    <>
                      <p>
                        男の子にも女の子にもお喜びいただけるとても可愛く大人気のベア風船と一緒にお誕生日のお祝いはいかがでしょうか。ご家族でより楽しいお子様のお誕生日をお過ごしいただけます。
                      </p>
                      <p className="u_fwb u_mt10">料金:20,000円</p>
                    </>
                  ),
                },
                {
                  img: {
                    src: '/assets/images/anniversary/balloon_anniversary/img_balloon_anniversary06.png',
                    alt: '',
                  },
                  title: <>バースデープラン 60th バースデー</>,
                  text: (
                    <>
                      <p>
                        ゴールドの60の数字を引き立たせてくれる上品な赤を組み合わせた装飾です。還暦を迎える方へ今までの感謝とこれからも健康でい続けてほしいという家族の願いを、鶴の背中に乗せて届くように願いながら装飾いたします。
                      </p>
                      <p className="u_fwb u_mt10">料金:25,000円</p>
                    </>
                  ),
                },
              ]}
            />
          </div>
          <div id="desktopPlan" className="u_pt100">
            <CSectTitle03 title="卓上タイププラン" />
            <p className='mb-5 ' style={{ lineHeight: '3rem' }}>コンパクトでありながらも、可愛らしく印象的なデコレーションもご用意しております。<br className='u_pc' />卓上タイプ限定でメッセージをお選びいただく事ができ、そのままプレゼントとしてお持帰りいただけます。
            </p>

            <CImgCard2
              col="5"
              data={[
                {
                  img: {
                    src: '/assets/images/anniversary/balloon_anniversary/img_balloon_tabletop01.png',
                    alt: '卓上タイプA',
                    bigSrc: '/assets/images/anniversary/balloon_anniversary/img_balloon_tabletop01_big.png '
                  },
                  title: <><p className="u_fwb">卓上タイプA<br />料金:10,000円</p></>,
                  subtitle: <></>,
                },
                {
                  img: {
                    src: '/assets/images/anniversary/balloon_anniversary/img_balloon_tabletop02.png',
                    bigSrc: '/assets/images/anniversary/balloon_anniversary/img_balloon_tabletop02_big.png ',
                    alt: '卓上タイプB'
                  },
                  title: <><p className="u_fwb">卓上タイプB<br />料金:10,000円</p> </>,
                  subtitle: <>※バルーンの色は、ゴールド・シルバーから選択可能</>,
                },
                {
                  img: {
                    src: '/assets/images/anniversary/balloon_anniversary/img_balloon_tabletop03.png',
                    bigSrc: '/assets/images/anniversary/balloon_anniversary/img_balloon_tabletop03_big.png',
                    alt: '卓上タイプC'
                  },
                  title: <><p className="u_fwb">卓上タイプC<br />料金:12,000円</p></>,
                },
                {
                  img: {
                    src: '/assets/images/anniversary/balloon_anniversary/img_balloon_tabletop04.png',
                    bigSrc: '/assets/images/anniversary/balloon_anniversary/img_balloon_tabletop04_big.png ',
                    alt: '卓上タイプD'
                  },
                  title: <><p className="u_fwb">卓上タイプD<br />料金:12,000円</p></>,
                  subtitle: <>※バルーンフラワー（造花）は、ピンク・ホワイトから選択可能</>,

                },
                {
                  img: {
                    src: '/assets/images/anniversary/balloon_anniversary/img_balloon_tabletop05.png',
                    bigSrc: '/assets/images/anniversary/balloon_anniversary/img_balloon_tabletop05_big.png ',
                    alt: '卓上タイプE'
                  },
                  title: <><p className="u_fwb">卓上タイプE<br />料金:13,000円</p></>,

                },

              ]} />


            <div className='content_balloon_anniversary_Birthday' >
              <p style={{ marginLeft: '2%' }} className='u_fwb'>卓上タイプ限定：お好きなメッセージを、ひとつお選びいただけます。</p>
              <p className='content_balloon_anniversary_Birthday_p'>・Happy Birthday　<br className='u_sp' />・Happy Anniversary　<br className='u_sp' />・Happy Wedding　<br className='u_sp' />・Congratulations　<br className='u_sp' />・Will you Marry me?</p>
            </div>
          </div>



          <div id="usage" className="l_sect">
            <CSectTitle03 title="ご利用方法" />
            <CTable04

              data={[
                {
                  title: <>STEP.1<br />ご宿泊の予約</>,
                  content: <>お電話またはオンラインにてご宿泊予約をお願いいたします。</>,
                },
                {
                  title: <>STEP.2<br />バルーンのご予約</>,
                  content: <>
                    <ul className="c_circleList c_circleList2">
                      <li>装飾日の1週間前までに、お電話にてご予約ください。</li>
                      <li>1.ご希望のバルーンデコレーション</li>
                      <li>2.装飾作業開始時間のご指定　チェックイン可能時間～20:00のうち30分単位（所要時間 2時間）
                        <p className='ft-12'>※上記以外の時間をご希望の際は、追加料金を申し受けます。</p>
                      </li>
                      <li>3.お支払い方法</li>
                    </ul>
                  </>,
                },
                {
                  title: <>STEP.3<br /><p>お支払い／キャンセル</p></>,
                  content: (
                    <>
                      <ul className="c_circleList">
                        <li>
                          ご宿泊当日または事前にご精算ください。
                        </li>
                        <li>キャンセルにつきましては、装飾日3日前 10:00以降はキャンセル料100％を申し受けます。</li>
                      </ul>

                    </>
                  ),
                },
              ]}
            />
            <CTable04

              data={[

                {
                  title: 'その他ご注意事項',
                  content: (
                    <>
                      <ul className="c_circleList05">
                        <li>・クリスマスや年末年始は、ご注文の締切りや料金が異なりますのでお早めにご検討ください。 </li>
                        <li>・入荷状況により、予告なくプランの内容が変更、休止または終了となる場合がございます。</li>
                        <li>・特別企画商品のため、各種特典・割引対象外です。</li>
                        <li>・掲載の写真は実際と異なる場合がございます。</li>
                        <li>・料金には消費税が含まれます。</li>
                      </ul>

                    </>
                  ),
                },
              ]}
            />
          </div>

        </LWrap>
      </section>
      <CBreadCrumb
        data={{
          parent: [
            {
              label: 'アニバーサリー',
              path: '/anniversary/',
            },
          ],
          current: {
            label: frontmatter?.title,
          },
        }}
      />
      <LContact03
        title={'ご予約・お問合せ'}
        btn={{
          btn: {
            label: 'お問合せフォーム',
            link: {
              href: 'https://contact.royalparkhotels.co.jp/rph/contact?_ifbs-rph_contact_form=s1_Step1',
              blank: true,
            },
          },
        }}
        tel={{
          number: '03-5641-2958',
          note: '宿泊営業課（受付時間 平日 8:00～20:00／土・日・祝日 9:00～18:00）',
        }}
      />
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
