import React, { useRef, useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import {
  Layout,
  SEO,
  LWrap,
  CJumbotron,
  CLocalBtnList,
  CSectTitle03,
  CFaqList,
  CBreadCrumb,
  LFaqContact,
  CAnchorNav,
} from '../../../components/_index';
import '../../../assets/_sass/page/faq.scss';

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;

  return (
    <Layout>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CJumbotron
        size="small"
        data={{
          title: {
            main: 'よくあるご質問',
            sub: 'FAQ',
          },
          imgList: [
            {
              img: {
                src: '/assets/images/faq/kv.png',
              },
              imgSp: {
                src: '/assets/images/faq/kv__sp.png',
              },
            },
          ],
        }}
      ></CJumbotron>

      <div className="p_faq">
        <section className="u_mtMedium">
          <LWrap>
            <CLocalBtnList
              data={[
                {
                  label: 'ご宿泊',
                  link: {
                    href: '/faq/',
                  },
                },
                {
                  label: 'レストラン＆バー',
                  link: {
                    href: '/faq/restaurants/',
                  },
                },
                {
                  label: 'ウエディング',
                  link: {
                    href: '/faq/wedding/',
                  },
                },
                {
                  label: '宴会・会議',
                  link: {
                    href: '/faq/banquet/',
                  },
                },
                {
                  label: 'お子様連れのお客様',
                  link: {
                    href: '/faq/child/',
                  },
                },
                {
                  label: 'バリアフリー',
                  link: {
                    href: '/faq/accessibility/',
                  },
                },
              ]}
            />
          </LWrap>
          {/* <LWrap size="small">
            <CAnchorNav
              exClass="u_mt50"
              data={[
                {
                  title: <>基本情報</>,
                  link: {
                    href: '#basic_info',
                  },
                },
                {
                  title: <>食事</>,
                  link: {
                    href: '#restaurants',
                  },
                },
                {
                  title: <>お子様連れ</>,
                  link: {
                    href: '#child',
                  },
                },
                {
                  title: <>設備・サービス</>,
                  link: {
                    href: '#facility',
                  },
                },
                {
                  title: <>アクセス</>,
                  link: {
                    href: '#access',
                  },
                },
                {
                  title: <>その他</>,
                  link: {
                    href: '#other',
                  },
                },
              ]}
            />
          </LWrap> */}
        </section>

        <section className="u_pt80" id="basic_info">
          <LWrap>
            <CSectTitle03 title="基本情報" />
            <CFaqList
              data={[
                {
                  q: 'サービス料はいくらですか?',
                  a: <>本体価格の15%をいただいております。</>,
                },
                {
                  q: '当日の支払いは、現金以外にどのような決済方法がありますか?',
                  a: (
                    <>
                      キャッシュレス決済につきましては、以下の通りご利用が可能です。
                      <br />
                      ・クレジットカード
                      <br />
                      　American
                      Express／VISA／DC／Diners／JCB／Master／UFJ／Nicos／銀聯カード
                      <br />
                      ・二次元バーコード決済
                      <br />
                      　ALIPAY／WeChatPay／メルペイ／楽天ペイ／PayPay／d払い／auPay／Bank Pay／J-Coin Pay
                    </>
                  ),
                },
                {
                  q: '商品券やギフト券は使えますか?',
                  a: (
                    <>
                      以下の商品券、ギフト券をご利用いただけます。
                      <br />
                      三菱UFJニコスギフトカード／JCBギフトカード／三菱地所グループ共通ギフトカード／ロイヤルパークホテルズギフト券
                      <br />
                      そのほかにつきましてはお問合せください。なお、券面金額未満のお支払いの場合はおつりが出ません。
                    </>
                  ),
                },
                {
                  q: '列席者の宿泊優待はありますか?',
                  a: (
                    <>
                      ご宴席の前日・当日・翌日のご宿泊を特別価格にてご利用いただけます。
                      <br />
                      ご予約につきましては主催者様からの事前予約にてお受けしております。
                    </>
                  ),
                },
                {
                  q: '宴会の予約は、いつからできますか?',
                  a: <>開催日の1年半前から承ります。</>,
                },
              ]}
            />
          </LWrap>
        </section>

        <section className="u_pt80" id="restaurants">
          <LWrap>
            <CSectTitle03 title="食事" />
            <CFaqList
              data={[
                {
                  q: '食物アレルギーがあるのですが、どのような対応していただけますか?',
                  a: (
                    <>
                      皆様にお食事をお楽しみいただけるよう、できる限りの対応をいたしております。
                      <br />
                      食物アレルギーをお持ちの方へのご案内は
                      <a href="/restaurants/allergy/">こちら</a>
                      をご確認ください。
                    </>
                  ),
                },
              ]}
            />
          </LWrap>
        </section>

        <section className="u_pt80" id="child">
          <LWrap>
            <CSectTitle03 title="お子様連れのお客様" />
            <CFaqList
              data={[
                {
                  q: 'おむつ替えのできる場所はありますか?',
                  a: (
                    <>
                      下記をご利用いただけます。
                      <br />
                      ベビーベッド：1F ロビー
                      フロントカウンター横（授乳室併設、ご利用の際はスタッフにお申し付けください）
                      <br />
                      折りたたみシート：1F バリアフリートイレ内
                      <br />
                      おむつ替えベッド：B1F、2F、4F 女性用化粧室内
                    </>
                  ),
                },
                {
                  q: '授乳室はありますか?',
                  a: (
                    <>
                      1F ロビー
                      フロントカウンター横にご用意しております（ご利用の際はスタッフにお申し付けください）。
                    </>
                  ),
                },
                {
                  q: '子どもを預けられるサービスはありますか?',
                  a: (
                    <>
                      ベビーシッターのご予約代行を承ります。詳細はお問合せください。
                      TEL <a href="tel:0336671111">03-3667-1111</a>（代表） 受付時間 7:30～24:00
                    </>
                  ),
                },
                {
                  q: '子供用の料理はありますか?',
                  a: <>ご用意いたしますので、詳しくはお問合せください。</>,
                },
              ]}
            />
          </LWrap>
        </section>

        <section className="u_pt80" id="facility">
          <LWrap>
            <CSectTitle03 title="設備・サービス" />
            <CFaqList
              data={[
                {
                  q: 'ホテル内にコンビニはありますか?',
                  a: <>ホテル内にはございませんが、近隣に数店舗ございます。</>,
                },
                {
                  q: '喫煙できる場所はありますか?',
                  a: (
                    <>
                      B1F
                      、2F、3Fに喫煙コーナーをご用意しております。電子タバコ、加熱式タバコも喫煙コーナーをご利用ください。
                      <br />
                      ホテル館内は客室内を含め全て禁煙でございます。
                    </>
                  ),
                },
                {
                  q: 'インターネットの利用・接続は可能でしょうか?',
                  a: (
                    <>
                      Wi-Fi接続サービスを導入しており、無料でお手持ちの対応機器からインターネット接続をご利用いただけます。
                      <br />
                      ※対象機器：キャリアを問わずWi-Fi規格に適合した機器はすべてご利用可能です。
                      <br />
                      ※スタッフよりパスワードをご案内させていただきます。利用規約にご承諾いただいた後、接続が可能となります。
                    </>
                  ),
                },
                {
                  q: '記念写真を撮影してくれるところはありますか?',
                  a: (
                    <>
                      4F 写真室
                      リトル・マーサにて、ご集合写真などの撮影を承ります。館内施設のご案内は
                      <a href="/about/facility/shop/">こちら</a>
                      をご確認ください。
                    </>
                  ),
                },
                {
                  q: '更衣室はありますか?',
                  a: <>4Fに紳士用、婦人用とそれぞれご用意しております。</>,
                },
                {
                  q: '荷物を預ける場所はありますか?',
                  a: (
                    <>
                      お荷物は各ご宴席フロア（2F・3F・4F）のクロークへお預けください。ご貴重品はお預かりいたしかねます。
                    </>
                  ),
                },
                {
                  q: '新札に変えてもらえますか?',
                  a: <>誠に恐れ入りますが、新札への交換は承っておりません。</>,
                },
                {
                  q: '列席者の衣裳レンタル、美容着付やヘアセットは可能ですか?',
                  a: (
                    <>
                      ご利用日の14日前までの事前予約が必要です。
                      衣裳レンタルは4F衣裳室 BRIDARIUM MUE（ブライダリウム
                      ミュー）、美容着付やヘアセットは4F美容室
                      ロイヤルパークホテル美容室にて承ります。詳細は
                      <a
                        href="https://www.rph.co.jp/wedding/for-guests/"
                        target="_blank"
                      >
                        こちら
                      </a>
                      をご覧ください。
                    </>
                  ),
                },
              ]}
            />
          </LWrap>
        </section>

        <section className="u_pt80" id="access">
          <LWrap>
            <CSectTitle03 title="アクセス" />
            <CFaqList
              data={[
                {
                  q: 'ホテルへの行き方を教えてください。',
                  a: (
                    <>
                      ホテルへのアクセスは<a href="/about/access/">こちら</a>
                      をご確認ください。
                    </>
                  ),
                },
                {
                  q: 'ホテルから東京シティエアターミナルへの行き方、バスの運行時間、所要時間、料金など教えてください。',
                  a: (
                    <>
                      正面玄関を出て左手の隣接している建物が東京シティエアターミナルです。ホテル2Fの専用通路もご利用いただけます。
                      <br />
                      羽田空港までの所要時間は約25分、約30分間隔で運行、成田空港までが約55分、約10分から15分間隔で運行しております。
                      <br />
                      東京シティエアターミナルの詳細は公式サイトをご確認ください。
                      <br />
                      東京シティ・エアターミナル株式会社
                      東京シティエアターミナル：
                      <a
                        href="https://www.tcat-hakozaki.co.jp/"
                        target="_blank"
                      >
                        https://www.tcat-hakozaki.co.jp/
                      </a>
                    </>
                  ),
                },
                {
                  q: '東京駅からの送迎バスはありますか?有料ですか?',
                  a: (
                    <>
                      地下鉄、タクシーなど公共交通機関をご利用ください。
                      <br />
                      また、無料巡回バスもご利用いただけます。詳細は
                      <a href="/about/tourism/">こちら</a>をご確認ください。
                    </>
                  ),
                },
                {
                  q: 'リムジンバスの予約はできますか?',
                  a: (
                    <>
                      リムジンバスの公式サイトにて成田空港・羽田空港ともにご予約可能です。
                      <br />
                      東京空港交通株式会社 リムジンバス予約Web：
                      <a
                        href="https://www.limousinebus.co.jp/guide/reserve/"
                        target="_blank"
                      >
                        https://www.limousinebus.co.jp/guide/reserve/
                      </a>
                    </>
                  ),
                },
                {
                  q: '駐車料金の優待はありますか?',
                  a: (
                    <>
                      ご列席に合わせて優待をご用意しております。
                      <br />
                      詳細は<a href="/about/access/#car">こちら</a>
                      をご確認ください。
                    </>
                  ),
                },
                {
                  q: '駐車場にEV充電設備はありますか?',
                  a: (
                    <>
                      ホテル館内にはございませんので、近隣のEV充電設備をご利用ください。
                    </>
                  ),
                },
              ]}
            />
          </LWrap>
        </section>

        <section className="u_pt80" id="other">
          <LWrap>
            <CSectTitle03 title="その他" />
            <CFaqList
              data={[
                {
                  q: '商品券やギフト券を購入できますか?',
                  a: (
                    <>
                      全国のロイヤルパークホテルズのご宿泊・ご飲食などにご利用いただけるギフト券を1F
                      スイーツ&ベーカリー 粋にて販売をしております。詳細は
                      <a href="/restaurants/iki/gifts/">こちら</a>
                      をご確認ください。
                    </>
                  ),
                },
                {
                  q: 'ペットの同伴は可能ですか?',
                  a: (
                    <>
                      動物の種類や大きさを問わず、盲導犬、聴導犬、介助犬以外のペットのご同伴はご遠慮いただいております。
                    </>
                  ),
                },
              ]}
            />
          </LWrap>
        </section>
      </div>
      <LFaqContact />
      <CBreadCrumb
        data={{
          parent: [],
          current: {
            label: frontmatter?.title,
          },
        }}
      />
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
