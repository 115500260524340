import React, { useRef, useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import {
  Layout,
  SEO,
  CJumbotron,
  CBreadCrumb,
  LWrap,
  CSectTitle,
  CStayPlanRecommend,
  CLocalNav,
  CSectTitle03,
  CGeneralCard,
  CGeneralCard02,
  CBtnList,
  CCard02,
  CSectMedia,
  LContact05,
} from '../../components/_index';
import stayPlanGet from '../../utils/stayPlan-get';

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;
  const stayPlanData = stayPlanGet().filter((item: any) => {
    return item.node.category.includes('記念日・プロポーズ');
  });

  return (
    <Layout>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CJumbotron
        size="large"
        type="ja"
        data={{
          title: {
            main: '記念日・プロポーズ',
            sub: <>ANNIVERSARY</>,
          },
          imgList: [
            {
              img: {
                src: '/assets/images/anniversary/kv.png',
              },
              imgSp: {
                src: '/assets/images/anniversary/kv__sp.png',
              },
            },
          ],
        }}
      ></CJumbotron>
      <div className="l_sect04 u_bgGray">
        <LWrap>
          <p className="u_tac_pc u_fs16 u_mb30">
            大切な記念日が、思い出に残る素敵な一日になりますように、
            <br />
            サプライズプランやアニバーサリープランを各種ご用意しております。
            <br />
            心を込めた粋なおもてなしで、大切な方への想いをカタチにするお手伝いをいたします。
          </p>
        </LWrap>
      </div>
      <section className="l_sect02 u_pt70">
        <LWrap>
          <CLocalNav
            exClass="u_mb0"
            data={[
              {
                img: {
                  src: '/assets/images/anniversary/img_anniversary.png',
                  alt: '',
                },
                link: {
                  href: '/anniversary#stay',
                },
                title: {
                  main: <>ご宿泊</>,
                },
              },
              {
                img: {
                  src: '/assets/images/anniversary/img_anniversary02.png',
                  alt: '',
                },
                link: {
                  href: '/anniversary#restaurant',
                },
                title: {
                  main: <>レストラン</>,
                },
              },
            ]}
          />
          <div id="stay" className="u_pt70">
            <CSectTitle03 title="ご宿泊" />
            <CGeneralCard
              exClass="sp_full"
              data={[
                {
                  img: {
                    src: '/assets/images/anniversary/img_anniversary03.png',
                    alt: '',
                  },
                  imgSp: {
                    src: '/assets/images/anniversary/img_anniversary03__sp.png',
                    alt: '',
                  },
                  link: {
                    href: '/anniversary/balloon_anniversary/',
                  },
                  title: 'バルーンデコレーション',
                  text: (
                    <>
                      特別な日にお過ごしになられるお部屋を素敵にデコレーションいたします。
                      <br />
                      お誕生日や記念日、プロポーズなど、シーンに合わせたバルーンをご用意しております。
                    </>
                  ),
                },
              ]}
            />
            <CCard02
              col={2}
              data={[
                {
                  img: {
                    src: '/assets/images/anniversary/img_anniversary04.png',
                    alt: '',
                  },
                  link: {
                    href: '/private/zxgej758veg/',
                  },
                  title: 'アニバーサリーケーキ',
                  text: (
                    <>
                      ルームサービスよりご希望のお時間にお届けします。
                      <br />
                      お持ちするタイミングやご要望等のご相談も承ります。
                    </>
                  ),
                },
                {
                  id: 'bouquet',
                  img: {
                    src: '/assets/images/anniversary/img_anniversary05.png',
                    alt: '',
                  },
                  title: '花束',
                  text: (
                    <>
                      <p>
                        花の種類や色のご指定などお客様のご要望に応じ、手配いたします。
                        <br />
                        メッセージカードのご用意も可能です。
                      </p>
                      <p className="u_fwb u_mt10">ご予約・お問合せ</p>
                      <p className="u_mt10">
                        店舗：
                        <a href="/about/facility/shop#gardenia">
                          B2F フラワーショップガーデニア
                        </a>
                      </p>
                      <ul className="c_noteList">
                        <li>定休日：水曜日（祝日を除く）</li>
                      </ul>
                    </>
                  ),
                },
                {
                  id: 'cocktail',
                  img: {
                    src: '/assets/images/anniversary/img_anniversary06.png',
                    alt: '',
                  },
                  title: 'お誕生日カクテル',
                  text: (
                    <>
                      <p>
                        お誕生日やアニバーサリーに合わせた色やイメージ、お好みに合わせたテイストで、バーテンダーがカクテルを創作いたします。
                      </p>
                      <p>
                        店舗：
                        <a href="/restaurants/royal_scots/">
                          B1F メインバー ロイヤルスコッツ
                        </a>
                      </p>
                    </>
                  ),
                },
                {
                  id: 'photostudio',
                  img: {
                    src: '/assets/images/anniversary/img_anniversary07.png',
                    alt: '',
                  },
                  title: '写真室のご予約',
                  text: (
                    <>
                      <p>
                        記念日の思い出にお写真を。
                        <br />
                        大切なひとときを形に残すお手伝いをいたします。
                      </p>
                      <p>
                        店舗：
                        <a href="/about/facility/shop#little_martha">
                          4F 写真室 リトル・マーサ
                        </a>
                      </p>
                      <ul className="c_noteList u_mt10">
                        <li>定休日：水曜日（祝日を除く）</li>
                        <li>ご利用日の2日前までに要ご予約</li>
                      </ul>
                    </>
                  ),
                },
              ]}
            />
            <CCard02
              exClass="u_mb0"
              data={[
                {
                  img: {
                    src: '/assets/images/anniversary/img_anniversary08.png',
                    alt: '',
                  },
                  title: (
                    <>
                      エグゼクティブラウンジ特典
                      <br />
                      アニバーサリープランナー
                    </>
                  ),
                  link: {
                    href: '/stay/floor/lounge#etc_service',
                  },
                },
                {
                  img: {
                    src: '/assets/images/anniversary/img_anniversary09.png',
                    alt: '',
                  },
                  title: <>お子様連れのお客様へ</>,
                  link: {
                    href: '/stay/child/',
                  },
                },
              ]}
            />
          </div>
        </LWrap>
      </section>
      <section className="l_sect02 u_bgGray" style={{ overflow: 'hidden' }}>
        <LWrap>
          <CSectTitle title={{ ja: 'おすすめプラン', en: 'PLAN' }} />
          <CStayPlanRecommend data={stayPlanData} />
          <CBtnList
            data={[
              {
                label: 'プラン一覧',
                link: {
                  href: `/stay/plan/?category=記念日・プロポーズ`,
                },
                color: 'bgWhite03',
              },
            ]}
          />
        </LWrap>
      </section>
      <section className="l_sect02">
        <LWrap>
          <div id="restaurant">
            <CSectTitle03 title="レストラン" />
            <CGeneralCard
              exClass="sp_full"
              data={[
                {
                  img: {
                    src: '/assets/images/anniversary/img_anniversary10.png',
                    alt: '',
                  },
                  imgSp: {
                    src: '/assets/images/anniversary/img_anniversary10__sp.png',
                    alt: '',
                  },
                  link: {
                    href: '/oiwai/oiwai_plan/',
                  },
                  title: 'お祝いプラン',
                  text: (
                    <>
                      ご家族のお誕生日や成人式、ご長寿のお祝いなど、ご家族の大切な日を個室でゆっくりとお楽しみいただけるプラン。
                      <br />
                      心を込めた料理とおもてなしでお迎えいたします。選べるお祝い商品もご用意しております。
                    </>
                  ),
                },
              ]}
            />
            <CCard02
              exClass="u_mb100"
              data={[
                {
                  img: {
                    src: '/assets/images/anniversary/img_anniversary12.png',
                    alt: '',
                  },
                  link: {
                    href: '/restaurants/private/',
                  },
                  title: '個室のご案内',
                  text: (
                    <>
                      大切な一日には、ゆったりとお過ごしいただける個室はいかがでしょうか。
                    </>
                  ),
                },
              ]}
            />
            <CSectMedia
              exClass="u_mb0"
              data={[
                {
                  img: {
                    src: '/assets/images/anniversary/img_anniversary13.png',
                    alt: '',
                  },
                  imgSp: {
                    src: '/assets/images/anniversary/img_anniversary13__sp.png',
                    alt: '',
                  },
                  link: {
                    href: '/oiwai/',
                  },
                  title: <>お祝い</>,
                  text: (
                    <>
                      安産祈願、ご出産、お子様の成長、ご家族でのお祝いに。シーンに合わせたプランを各種ご用意しております。
                    </>
                  ),
                },
              ]}
            />
          </div>
        </LWrap>
      </section>
      <CBreadCrumb
        data={{
          parent: [],
          current: {
            label: frontmatter?.title,
          },
        }}
      />
      <LContact05
        title="ご予約・お問合せ"
        telleft={{
          title: 'ご宿泊',
          number: '03-3667-1111',
          subText: '（代表）',
          note: '受付時間 7:30～24:00',
        }}
        telright={{
          title: 'レストラン',
          number: '03-5641-3600',
          note: 'レストラン予約（受付時間 9:00~19:00）',
        }}
        btn={{
          btn: {
            label: 'お問合せフォーム',
            link: {
              href: 'https://contact.royalparkhotels.co.jp/rph/contact?_ifbs-rph_contact_form=s1_Step1',
              blank: true,
            },
          },
        }}
      />
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
