import React, { useRef, useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import {
  Layout,
  SEO,
  LWrap,
  CJumbotron,
  CGoogleMap,
  CAnchorNav02,
  CSectTitle03,
  CInlineBorderList,
  CDefinition,
  CSectTitle04,
  CCard02,
  CBtnList,
  CGeneralModal,
  CBreadCrumb,
  CSearch,
} from '../../../components/_index';
import '../../../assets/_sass/page/access.scss';

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;
  const [modalFlag, setModalFlag] = useState(false);
  const openModal = (e: any) => {
    e.stopPropagation();
    setModalFlag(!modalFlag);
  };
  return (
    <Layout>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CJumbotron
        size="large"
        data={{
          title: {
            main: 'アクセス',
            sub: 'ACCESS',
          },
          imgList: [
            {
              img: {
                src: '/assets/images/about/access/kv.png',
              },
              imgSp: {
                src: '/assets/images/about/access/kv__sp.png',
              },
            },
          ],
        }}
      ></CJumbotron>

      <div className="p_access">
        <section id="gmap" className="u_pt100 u_pb100">
          <LWrap exClass="u_mbSmall">
            <div className="p_access_info">
              <div className="text">
                <p className="u_fwb u_fs16 u_mb25_sp">ロイヤルパークホテル</p>
                <p className="detail u_mb25_sp">
                  〒103-8520　
                  <br className="u_sp" />
                  東京都中央区日本橋蛎殻町2-1-1
                  <br />
                  TEL 03-3667-1111（代表）
                  <br />
                  受付時間 7:30～24:00
                </p>
              </div>
              <div className="gmap">
                <a href="https://goo.gl/maps/cGUXQC8YtnK36AH49" target="_blank">
                  Google Maps<i className="icon-blank"></i>
                </a>
              </div>
            </div>
          </LWrap>
          <CGoogleMap />
        </section>

        <section>
          <LWrap>
            <CAnchorNav02
              exClass="u_mb0 u_mb100"
              data={[
                {
                  title: (
                    <>
                      電車で
                      <br className="u_sp" />
                      お越しの方
                    </>
                  ),
                  link: {
                    href: '/about/access/#train',
                  },
                },
                {
                  title: (
                    <>
                      車で
                      <br className="u_sp" />
                      お越しの方
                    </>
                  ),
                  link: {
                    href: '/about/access/#car',
                  },
                },
                {
                  title: (
                    <>
                      空港から
                      <br className="u_sp" />
                      お越しの方
                    </>
                  ),
                  link: {
                    href: '/about/access/#airport',
                  },
                },
              ]}
            />
          </LWrap>
        </section>

        <div id="train" className="u_pt100">
          <LWrap>
            <CSectTitle03 title="電車でお越しの方" />
            <CInlineBorderList
              data={[
                {
                  text: (
                    <>
                      東京メトロ半蔵門線
                      <br />
                      「水天宮前」駅に直結（4番出口）
                    </>
                  ),
                },
                {
                  text: (
                    <>
                      東京メトロ日比谷線
                      <br />
                      「人形町」駅より徒歩約5分（A2出口）
                    </>
                  ),
                },
                {
                  text: (
                    <>
                      都営浅草線
                      <br />
                      「人形町」駅より徒歩約8分（A3出口）
                    </>
                  ),
                },
              ]}
            />
            <ul className="c_noteList u_tac_pc">
              <li>
                所要時間は目安です。乗り換え、待ち時間は含まれておりません。また運行状況により多少異なります。
              </li>
            </ul>
          </LWrap>
        </div>

        <div id="car" className="u_pt100">
          <LWrap>
            <CSectTitle03 title="車でお越しの方" />
            <CInlineBorderList
              data={[
                {
                  text: (
                    <>
                      「東京」駅より
                      <br />
                      約10分（2km）
                    </>
                  ),
                },
                {
                  text: (
                    <>
                      羽田空港（東京国際空港）より
                      <br />
                      約25分
                    </>
                  ),
                },
                {
                  text: (
                    <>
                      成田国際空港より
                      <br />
                      約55分
                    </>
                  ),
                },
              ]}
            />
            <ul className="c_noteList u_tac_pc u_mbSmall">
              <li>
                首都高速をご利用の場合、「箱崎ランプ」または「浜町ランプ」が便利です。ホテル周辺は一方通行が多いため、
                <a href="/assets/files/pdf/a1642998374109.pdf">こちら</a>
                の地図をご確認ください。
              </li>
            </ul>
            <p className="u_fwb u_fs16 u_mb10">駐車場</p>
            <CDefinition
              data={[
                {
                  title: '収容台数',
                  text: <>176台</>,
                },
                {
                  title: '車高制限',
                  text: <>高さ2.3mまで</>,
                },
                {
                  title: '料金',
                  text: (
                    <>
                      お車をお停めいただいた際、駐車券をお持ちいただき、会計時にお申し出ください。
                      <table className="p_priceTable u_mt20 u_mb20">
                        <tr className="u_mb20">
                          <th className="u_tal standard">通常料金</th>
                          <td className="standard">30分 300円</td>
                        </tr>
                        <tr>
                          <th rowSpan={4} className="u_tal special">
                            ご優待料金・時間のご案内
                          </th>
                          <td>
                            <span className="sub_title">ご宿泊のお客様</span>
                            <span>1泊につき1台1,500円</span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <span className="sub_title">
                              レストランご利用のお客様
                            </span>
                            <span>3,000円以上のご利用で2時間無料</span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <span className="sub_title">
                              ご宴会に出席されるお客様
                            </span>
                            <span>3時間無料</span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <span className="sub_title">
                              ご披露宴に出席されるお客様
                            </span>
                            <span>5時間無料</span>
                          </td>
                        </tr>
                      </table>
                      <ul className="c_noteList u_mt10">
                        <li>
                          上記の優待時間を過ぎた場合には、通常料金〔30分
                          300円〕のご負担となりますので、ご了承ください。
                        </li>
                        <li>
                          ホテル駐車場満車の場合のみ東京シティエアターミナルの駐車場でもご利用いただけます。
                        </li>
                        <li>料金には消費税が含まれます。</li>
                        <li>
                          駐車場精算機の新紙幣対応について
                          <br />
                          現在、駐車場の出口精算機は旧紙幣のみのご対応可能です。新紙幣から旧紙幣への両替はフロントにて承ります。
                          <br />
                          ご不便をおかけいたしますが、ご理解を賜りますようお願い申しあげます。
                        </li>
                      </ul>
                    </>
                  ),
                },
              ]}
            />
          </LWrap>
        </div>

        <div id="airport" className="u_pt100 u_mbSmall">
          <LWrap exClass="">
            <CSectTitle03 title="空港からお越しの方" />
            <p className="c_sectLead">
              東京シティエアターミナル（T-CAT）に隣接しており、リムジンバスのご利用が大変便利です。
            </p>
            <div className="p_access_airport">
              <div className="info_wrap u_mb30_sp">
                <p className="u_fwb u_fs16 u_mb10">羽田空港（東京国際空港）</p>
                <dl>
                  <dt className="u_fwb u_mb10">リムジンバスのご案内</dt>
                  <dd className="u_mb15">
                    東京シティエアターミナル（T-CAT）←→羽田空港
                    <br />
                    所要時間：約25分
                    <br />
                    運賃：大人1,000円／小人500円
                  </dd>
                  <dd>
                    リムジンバスの詳細につきましては、
                    <a href="http://www.limousinebus.co.jp/" target="_blank">
                      公式サイト
                      <i className="icon-blank"></i>
                    </a>
                    をご確認ください。
                  </dd>
                </dl>
              </div>
              <div className="info_wrap">
                <p className="u_fwb u_fs16 u_mb10">成田国際空港</p>
                <dl>
                  <dt className="u_fwb u_mb10">リムジンバスのご案内</dt>
                  <dd className="u_mb15">
                    東京シティエアターミナル（T-CAT）←→成田空港
                    <br />
                    所要時間：約55分
                    <br />
                    運賃：大人3,100円／小人1,550円
                  </dd>
                  <dd>
                    リムジンバスの詳細につきましては、
                    <a href="http://www.limousinebus.co.jp/" target="_blank">
                      公式サイト
                      <i className="icon-blank"></i>
                    </a>
                    をご確認ください。
                  </dd>
                </dl>
              </div>
            </div>

            <CBtnList
              data={[
                {
                  label: 'ポーターサービスのご案内',
                  color: 'borderBlack',
                  size: 'large',
                  icon: 'modal',
                  tag: 'button',
                  onClick: openModal,
                  link: {
                    href: '#',
                  },
                },
              ]}
            />

            {modalFlag && (
              <CGeneralModal onClose={openModal} exClass="p_access_modal">
                <h3 className="c_headingLv2 u_tal">
                  東京シティエアターミナル（T-CAT）到着ロビー
                </h3>
                <CCard02
                  col={1}
                  data={[
                    {
                      img: {
                        src: '/assets/images/about/access/img_modal.png',
                        alt: '',
                      },
                      title: <>ポーターサービスのご案内</>,
                      content: (
                        <>
                          <p className="c_sectLead u_tal u_mb25">
                            ホテルに隣接する東京シティエアターミナル（T-CAT）の到着ロビーに、ホテル直通電話をご用意しております。ご到着、ご出発の際、お荷物をお運びしますのでお申し付けください。
                          </p>
                          <p className="c_sectLead u_tal">
                            ロイヤルパークホテルへの道順
                            <br />
                            ・1Fからは横断歩道を渡りすぐ正面玄関がございます。
                            <br />
                            ・2Fからは連絡通路がございます。
                          </p>
                        </>
                      ),
                    },
                  ]}
                />
              </CGeneralModal>
            )}

            <CBtnList
              data={[
                {
                  label: '観光に便利なサービス',
                  color: 'borderBlack',
                  link: {
                    href: '/about/tourism/',
                  },
                },
                {
                  label: 'ホテル周辺のご案内',
                  color: 'borderBlack',
                  link: {
                    href: '/about/surroundings/',
                  },
                },
              ]}
            />
          </LWrap>
        </div>
      </div>

      <CBreadCrumb
        data={{
          parent: [
            {
              label: 'ホテルについて',
              path: '/about/',
            },
          ],
          current: {
            label: frontmatter?.title,
          },
        }}
      />
      <CSearch formId="global" />
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
