import React, { useRef, useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import {
  Layout,
  SEO,
  LWrap,
  CJumbotron,
  CCard,
  CSectTitle03,
  CFaqList,
  CBtnList,
  CBreadCrumb,
  LContact03,
} from '../../../../components/_index';

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;

  return (
    <Layout>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CJumbotron
        size="large"
        data={{
          title: {
            main: 'バリアフリーのご案内',
            sub: 'ACCESSIBILITY',
          },
          imgList: [
            {
              img: {
                src: '/assets/images/about/facility/accessibility/kv.png',
              },
              imgSp: {
                src: '/assets/images/about/facility/accessibility/kv__sp.png',
              },
            },
          ],
        }}
      ></CJumbotron>

      <section className="u_pt70 u_pb100">
        <LWrap>
          <p className="c_sectLead">
            お越しいただいたすべてのお客様にとって安全・快適なご滞在となりますよう、
            館内の設備はもちろん、スタッフ一同お客様にご満足いただけるサービスをご提供してまいります。
          </p>
        </LWrap>
      </section>

      <section className="u_mb80_sp">
        <LWrap>
          <CCard
            data={[
              {
                img: {
                  src: '/assets/images/about/facility/accessibility/img_accessibility.png',
                  alt: '',
                },
                title: <>バリアフリートイレ　</>,
                content: <>1F ロビーへ設置しております。</>,
              },
              {
                img: {
                  src: '/assets/images/about/facility/accessibility/img_accessibility02.png',
                  alt: '',
                },
                title: <>車椅子の貸出</>,
                content: (
                  <>ベルキャプテンデスク・3Fクロークにて受付いたします。</>
                ),
              },
              {
                img: {
                  src: '/assets/images/about/facility/accessibility/img_accessibility03.png',
                  alt: '',
                },
                title: <>車椅子用のエレベーター</>,
                content: <>ボタン配置が低位置にございます。</>,
              },
              {
                img: {
                  src: '/assets/images/about/facility/accessibility/img_accessibility04.png',
                  alt: '',
                },
                title: <>スロープ</>,
                content: (
                  <>
                    ホテル館内は段差を解消するためのスロープが各所にございます。
                  </>
                ),
              },
              {
                img: {
                  src: '/assets/images/about/facility/accessibility/img_accessibility05.png',
                  alt: '',
                },
                title: <>駐車場の優先スペース</>,
                content: <>車椅子使用者優先スペースがございます。（1台）</>,
              },
              {
                img: {
                  src: '/assets/images/about/facility/accessibility/img_accessibility06.png',
                  alt: '',
                },
                title: <>補助犬（盲導犬・介助犬・補導犬）</>,
                content: (
                  <>
                    床シートとお皿のご用意も可能です。
                    <br />
                    ご宿泊・レストランご利用の際は事前にご相談ください。
                  </>
                ),
              },
              {
                img: {
                  src: '/assets/images/about/facility/accessibility/img_accessibility07.png',
                  alt: '',
                },
                title: <>お部屋へ貸出備品のご用意</>,
                content: (
                  <>
                    振動式ドアノックセンサー・アラームセンサー等フロントスタッフまでお申し付けください。
                  </>
                ),
              },
              {
                img: {
                  src: '/assets/images/about/facility/accessibility/img_accessibility08.png',
                  alt: '',
                },
                title: <>筆談機でのご案内</>,
                content: <>筆談器はフロントにてご用意しております。</>,
              },
              {
                img: {
                  src: '/assets/images/about/facility/accessibility/img_accessibility09.png',
                  alt: '',
                },
                title: <>アクセシブルルーム</>,
                content: <>段差がなく使いやすさに配慮したお部屋です。</>,
              },
            ]}
          />
        </LWrap>
      </section>

      <section className="u_pb100">
        <LWrap>
          <CSectTitle03
            title={
              <>
                バリアフリーに関する
                <br />
                よくあるご質問
              </>
            }
          />

          <CFaqList
            data={[
              {
                q: '車椅子や高齢の方に対応できるお部屋はありますか?',
                a: (
                  <>
                    アクセシブルルームのご用意がございます。お部屋の数に限りがありますので事前に空室状況をご確認ください。
                    <br />
                    アクセシブルルームの詳細は
                    <a href="/stay/accessible/">こちら</a>
                    をご確認ください。
                  </>
                ),
              },
              {
                q: '車椅子のまま利用できるレストランはありますか?',
                a: <>全レストランにてご利用いただけます。</>,
              },
              {
                q: '車椅子の貸出はありますか?',
                a: (
                  <>
                    お貸し出しは可能ですが、数に限りがございます。事前にお問合せください。
                  </>
                ),
              },
              {
                q: '車椅子対応の化粧室はありますか?',
                a: <>1F バリアフリートイレをご利用ください。</>,
              },
            ]}
          />

          <CBtnList
            data={[
              {
                label: 'よくあるご質問',
                color: 'borderBlack',
                link: {
                  href: '/faq/',
                },
              },
            ]}
          />
        </LWrap>
      </section>

      <CBreadCrumb
        data={{
          parent: [
            {
              label: 'ホテルについて',
              path: '/about/',
            },
          ],
          current: {
            label: frontmatter?.title,
          },
        }}
      />
      <LContact03
        title="お問合せ"
        btn={{
          btn: {
            label: 'お問合せフォーム',
            link: {
              href: 'https://contact.royalparkhotels.co.jp/rph/contact?_ifbs-rph_contact_form=s1_Step1',
              blank: true,
            },
          },
        }}
        tel={{
          number: '03-3667-1111',
          subText: '（代表）',
          note: '受付時間 7:30～24:00',
        }}
      />
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
